@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

.bg-intro {
    background-image: url("../public/images/bg-3.svg");
    background-repeat: no-repeat;
    background-position: calc(100vw / 2 - 80px) 70px;
}
.bg-about-me {
    background-image: url("../public/images/bg-4.svg");
    background-repeat: no-repeat;
    background-position: calc(100vw / 2 - 636px) 36px;
}
.bg-text {
    background: linear-gradient(205deg, #420dbd 63%, #6e46e5 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.bg-reviews {
    background-image: url("../public/images/bg-5.svg");
    background-repeat: no-repeat;
    background-position: 50px center;
}
.animation {
    animation: rotate-animation 5s infinite linear;
}
@keyframes rotate-animation {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.my-blur {
    filter: blur(102.50411987304688px);
}
.my-blur-filter {
    backdrop-filter: blur(102.50411987304688px);
}
.swiper-slide-active {
    min-width: 345px;
    height: 446px !important;
}
.swiper-slide-active > div > div {
    background-color: initial;
}
.swiper-wrapper {
    align-items: flex-end;
}
body.lock {
    overflow: hidden;
}
html {
    scroll-behavior: smooth;
}

@media (max-width: 768px) {
    .bg-intro {
        background-position: calc(100vw / 2 - 80px) 35px;
    }
    .bg-about-me {
        background-image: none;
    }
}
@media (max-width: 550px) {
    .bg-intro {
        background-position: calc(100vw / 2 - 255px) bottom;
        background-size: 450px !important;
    }
    .intro-before {
        &::before {
            content: url("../public/images/line.svg") !important;
            border: none;
            right: 0 !important;
        }
    }
    .bg-reviews {
        background-image: url("../public/images/bg-6.svg");
        background-position: 30px center;
    }
    .swiper-slide-active {
        min-width: auto;
        height: auto !important;
    }
    .swiper-wrapper {
        align-items: flex-start;
    }
}
@media (max-width: 320px) {
    .bg-intro {
        background-position: -100px bottom;
    }
}
